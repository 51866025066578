<template>
    <v-container fluid>
        <v-form
            ref="form"
            v-model="validForm"
            lazy-validation
        >
            <v-row>
                <v-col 
                    style="display: flex; align-content: center; align-items: center;"
                    cols="12"
                    lg="12"
                    md="12"
                    sm="12"
                >
                    <h3>Job Settings</h3>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <hr />
                </v-col>
            </v-row>
            <v-row>
                <v-col 
                    cols="12"
                    lg="4"
                    md="4"
                    sm="12"
                >
                    <label>Module</label>
                    <v-combobox
                        v-model="jobSettingsRequest.moduleSelected"
                        :items="listModules" 
                        :rules=[validations.required]
                        item-text="moduleDescription"
                        item-value="id"
                        clearable
                        outlined
                        dense
                    ></v-combobox>
                </v-col>
                <v-col 
                    v-if="showPanelAndStage"
                    cols="12"
                    lg="4"
                    md="4"
                    sm="12"
                >
                    <label>Panel</label>
                    <v-combobox
                        v-model="jobSettingsRequest.panelSelected"
                        :items="listConstructionPanel" 
                        :rules=[validations.required]
                        item-text="panelDescription"
                        item-value="id"
                        clearable
                        outlined
                        dense
                        @change="filterStages()"
                    ></v-combobox>
                </v-col>
                <v-col 
                    v-if="showPanelAndStage"
                    cols="12"
                    lg="4"
                    md="4"
                    sm="12"
                >
                    <label>Stage</label>
                    <v-combobox
                        v-model="jobSettingsRequest.stageSelected"
                        :items="listConstructionStagesFiltered" 
                        :rules=[validations.required]
                        item-text="stageDescription"
                        item-value="id"
                        clearable
                        outlined
                        dense
                    ></v-combobox>
                </v-col>
                <v-col 
                    cols="12"
                    lg="4"
                    md="4"
                    sm="12"
                >
                    <label>Level</label>
                    <v-combobox
                        v-model="jobSettingsRequest.levelSelected"
                        :items="listLevels" 
                        :rules=[validations.required]
                        item-text="levelDescription"
                        item-value="id"
                        clearable
                        outlined
                        dense
                    ></v-combobox>
                </v-col>
                <v-col 
                    cols="12"
                    lg="4"
                    md="4"
                    sm="12"
                >
                    <label>Days</label>
                    <v-text-field
                        v-model="jobSettingsRequest.days"
                        v-mask="'###'"
                        :rules=[validations.required]
                        single-line
                        outlined
                        dense
                    >
                    </v-text-field>
                </v-col>
            </v-row>

            <ActionButtons
                :request="jobSettingsRequest"
                :loading="loading"
                :showButtons="{
                    cancel: true,
                    save: true
                }"
                @cancel="cancel"
                @save="save"
            />

            <ActionDialog 
                v-on:methodConfirmToCall="dialog.methodConfirm"
                :showDialog.sync="dialog.show"
                :headerTitle="dialog.headerText"
                :bodyText="dialog.bodyText"
                :params="dialog.params"
            />
        </v-form>

    </v-container>
</template>

<script>
    import { required, validEmail } from "@/utilities/Rules";
    import Helpers from '@/utilities/Helpers';
    import ActionButtons from "@/components/Layout/ActionButtons";
    import ActionDialog from "@/components/Layout/ActionDialog";
    import { MODULES_NAMES } from "@/utilities/Enums";

    export default ({

        mixins: [Helpers],

        components: {
            ActionButtons,
            ActionDialog
        },

        data: () => ({

            validForm: true,

            jobSettingsRequest: {
                id: 0,

                idModule: 0,
                moduleSelected: null,

                idPanel: 0,
                panelSelected: null,

                idStage: 0,
                stageSelected: null,

                idLevel: 0,
                levelSelected: null,

                days: 0
            },

            listModules: [],
            listConstructionPanel: [],
            listConstructionStages: [],
            listConstructionStagesFiltered: [],
            listLevels: [],
            
            dialog: {
                show: false,
                headerText: "",
                bodyText: "",
                methodConfirm: ( () => {} ),
                params: null
            },

            loading: false,
            
            validations: {
                required: required,
                email: validEmail,
            },

            MODULES_NAMES
        }),

        props: {
            id: {
                default: 0
            }
        },

        computed: {
            showPanelAndStage() {
                return this.jobSettingsRequest.moduleSelected != null &&
                       this.jobSettingsRequest.moduleSelected != undefined &&
                       this.jobSettingsRequest.moduleSelected.id == this.MODULES_NAMES.CONSTRUCTION.id
            }
        },

        methods: {
            
            async getRegister() {

                if (this.id !== 0) {

                    const response = await this.$store.dispatch("serviceSettingsModule/GetById", this.id);

                    if (response.success) {
                        this.jobSettingsRequest = response.result;

                        console.log('this.jobSettingsRequest', this.jobSettingsRequest);
                    }
                }
            },

            async getLists() {
                this.listModules = await this.$store.dispatch("moduleModule/List");
                this.listConstructionPanel = await this.$store.dispatch("serviceModule/ListConstructionPanel");
                this.listConstructionStages = await this.$store.dispatch("serviceModule/ListConstructionStages");
                this.listLevels = await this.$store.dispatch("serviceSettingsModule/ListLevels");
            },

            filterStages() {
                if (this.jobSettingsRequest.panelSelected != null && this.jobSettingsRequest.panelSelected != undefined) {
                    this.jobSettingsRequest.stageSelected = null;
                    this.listConstructionStagesFiltered = this.listConstructionStages.filter(stage => stage.idPanel == this.jobSettingsRequest.panelSelected.id);
                }
            },

            async cancel() {
                this.$router.push({ path: "/admin/jobSettingsList" });
            },            

            async save() {

                await this.$refs.form.validate();

                if (this.validForm === false) {
                    this.showToast("error", "Warning!", "There are inconsistencies in the form. Please review!");
                    return false;
                }
                else {
                    this.loading = true;
                    this.showLoading();

                    if (this.jobSettingsRequest.moduleSelected != null && this.jobSettingsRequest.moduleSelected != undefined) {
                        this.jobSettingsRequest.idModule = this.jobSettingsRequest.moduleSelected.id;
                    }

                    if (this.jobSettingsRequest.panelSelected != null && this.jobSettingsRequest.panelSelected != undefined) {
                        this.jobSettingsRequest.idPanel = this.jobSettingsRequest.panelSelected.id;
                    }

                    if (this.jobSettingsRequest.stageSelected != null && this.jobSettingsRequest.stageSelected != undefined) {
                        this.jobSettingsRequest.idStage = this.jobSettingsRequest.stageSelected.id;
                    }

                    if (this.jobSettingsRequest.levelSelected != null && this.jobSettingsRequest.levelSelected != undefined) {
                        this.jobSettingsRequest.idLevel = this.jobSettingsRequest.levelSelected.id;
                    }

                    if (this.jobSettingsRequest.idModule == this.MODULES_NAMES.CONSTRUCTION.id) {
                        this.jobSettingsRequest.idPanel = 0;
                        this.jobSettingsRequest.idStage = 0;
                    }

                    this.jobSettingsRequest.id = this.id;

                    const result = await this.$store.dispatch("serviceSettingsModule/CreateUpdate", this.jobSettingsRequest);

                    if (result.success === true) {

                        this.showToast("success", "Success!", result.message);
                        this.cancel();
                    }
                    else {
                        this.showToast("error", "Warning!", result.message);
                    }

                    this.loading = false;
                    this.hideLoading();
                }
            }
        },

        async created() {
            this.showLoading();

            await this.getRegister();
            await this.getLists();

            this.hideLoading();
        }
    })
</script>